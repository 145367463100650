
/* eslint-disable no-undef */
import {
  IonContent,
  IonAvatar,
  IonButton,
  IonIcon,
  IonFooter,
  IonToolbar,
  IonGrid,
  IonCol,
  IonRow,
} from '@ionic/vue';
import {
  defineComponent,
  ref,
  toRefs,
  PropType,
  computed,
} from 'vue';
import { cubeOutline, shareSocialOutline } from 'ionicons/icons';
import { Job } from '@/types/merchant';
import { onShare } from '@/composables/utils';
import StatusDropdown from './StatusDropdown.vue';

export default defineComponent({
  components: {
    IonContent,
    IonAvatar,
    IonButton,
    IonIcon,
    IonFooter,
    IonGrid,
    IonCol,
    IonRow,
    IonToolbar,
    StatusDropdown,
  },
  props: {
    delivery: Object as PropType<Job>,
    updateOrderStatus: Function,
    close: { type: Function },
  },
  setup(props) {
    const { close, delivery } = toRefs(props);

    const originCoordinates = computed(
      () =>
        delivery.value &&
        `${delivery.value?.pickup?.latitude},${delivery.value?.pickup?.longitude}`,
    );

    const share = () => {
      const title = 'JOB DETAILS';
      const text = `PICKUP INFORMATION:- \n NAME: ${delivery.value?.pickup.name} \n PHONE: ${delivery.value?.pickup.phone} \n ADDRESS: ${delivery.value?.pickup.address} \n DESTINATION INFORMATION:- \n NAME: ${delivery.value?.destination.name} \n PHONE: ${delivery.value?.destination.phone} \n ADDRESS: ${delivery.value?.destination.address}`;
      onShare({
        title,
        text,
      });
    };

    const destinationCoordinates = computed(
      () =>
        delivery.value &&
        `${delivery.value?.destination?.latitude},${delivery.value?.destination?.longitude}`,
    );

    // const directionsService = new google.maps.DirectionsService();

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    };

    const closeModal = ref(close.value);

    const iFrameEmbedUrl = computed(() => {
      return `https://www.google.com/maps/embed/v1/directions?key=AIzaSyDki2UXRNwKo7KIkfyjRcCfXUvwxu6NDbU&origin=${originCoordinates.value}&destination=${destinationCoordinates.value}&avoid=tolls|highways`;
    });

    const deliveryAmount = computed(() =>
      delivery?.value?.transaction?.length
        ? delivery?.value?.transaction[0].amount / 100
        : 0,
    );

    // const deliveryStatus = computed(() => {
    //   const not_set = '0000-00-00 00:00:00';

    //   if (delivery.value?.completed_datetime !== not_set) {
    //     return 'Completed';
    //   } else if (delivery.value?.arrived_datetime !== not_set) {
    //     return 'Arrived';
    //   } else if (delivery.value?.acknowledged_datetime !== not_set) {
    //     return 'Acknowledged';
    //   } else if (delivery.value?.started_datetime !== not_set) {
    //     return 'Started';
    //   } else {
    //     return 'Not started';
    //   }
    // });

    // onMounted(() => {
    //   const route = {
    //     origin: originCoordinates.value,
    //     destination: destinationCoordinates.value,
    //     travelMode: google.maps.TravelMode.DRIVING,
    //   };

    //   return new Promise(() => {
    //     directionsService.route(route, (response, status) => {
    //       if (status !== 'OK') {
    //         return [];
    //       }
    //       const [firstLeg, _] = response.routes[0].legs;

    //       if (firstLeg) {
    //         deliveryDistance.value = firstLeg.distance.text;
    //         deliveryDuration.value = firstLeg.duration.text;
    //       }
    //     });
    //   });
    // });

    return {
      deliveryAmount,
      share,
      closeModal,
      cubeOutline,
      formatDate,
      iFrameEmbedUrl,
      shareSocialOutline,
      // deliveryStatus,
      // deliveryDistance,
      // deliveryDuration,
    };
  },
});
