import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-303f71bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_select, {
      ref: "dropdownRef",
      onIonChange: _cache[0] || (_cache[0] = e => _ctx.updateOrderStatus(_ctx.id, e.target.value)),
      value: _ctx.value
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.status, (val) => {
          return (_openBlock(), _createBlock(_component_ion_select_option, {
            key: val,
            value: val.toLowerCase(),
            disabled: 
          _ctx.value === val.toLowerCase() || _ctx.value === 'completed'
        
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(val), 1)
            ]),
            _: 2
          }, 1032, ["value", "disabled"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}