import {
  loadingController,
  modalController,
  toastController,
  popoverController,
  alertController,
} from '@ionic/vue';
import {
  ComponentRef,
  ComponentProps,
} from '@ionic/core/dist/types/interface';

const LOADING_TIMEOUT = 4000;
const TOAST_TIMEOUT = 2000;

export async function getLoading(
  loadingMessage?: string,
  cssClass?: string,
) {
  return await loadingController.create({
    cssClass,
    message: loadingMessage || 'Loading...',
    duration: LOADING_TIMEOUT,
  });
}

export async function loading(
  loadingMessage?: string,
  cssClass?: string,
) {
  const loader = await loadingController.create({
    spinner: 'lines',
    cssClass,
    message: loadingMessage || 'Loading...',
  });
  return loader;
}

export async function openToast(message: string): Promise<void> {
  const toast = await toastController.create({
    message,
    duration: TOAST_TIMEOUT,
  });
  return toast.present();
}

export async function modal(
  component: ComponentRef,
  componentProps?: ComponentProps<ComponentRef>,
  cssClass?: string,
) {
  const modal = await modalController.create({
    component,
    cssClass,
    componentProps: {
      close: async () => modal.dismiss(),
      ...componentProps,
    },
  });

  return modal;
}

export async function popover(
  ev: Event,
  component: ComponentRef,
  componentProps?: ComponentProps<ComponentRef>,
  cssClass?: string,
): Promise<void> {
  const popover = await popoverController.create({
    component,
    cssClass,
    event: ev,
    // translucent: true,
    mode: 'md',
    componentProps: {
      close: async () => popover.dismiss(),
      ...componentProps,
    },
  });
  await popover.present();
}

type AlertOptions = {
  cssClass: string;
  header: string;
  message: string;
  cancelButton: {
    text: string;
    role: string;
    cssClass: string;
    handler: () => void;
  };
  confirmButton: {
    text: string;
    role: string;
    cssClass: string;
    handler: () => void;
  };
};

export async function ionAlert(options: AlertOptions): Promise<void> {
  const {
    cssClass,
    header,
    message,
    cancelButton,
    confirmButton,
  } = options;
  const alert = await alertController.create({
    cssClass,
    backdropDismiss: false,
    header,
    message,
    buttons: [
      {
        ...cancelButton,
      },
      {
        ...confirmButton,
      },
    ],
  });
  await alert.present();
}
