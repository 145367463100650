
import { IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { IonSelect, IonSelectOption },
  props: {
    id: Number,
    updateOrderStatus: Function,
    value: String,
    isStatusLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const status = ['PENDING', 'ONGOING', 'COMPLETED', 'CANCELLED'];
    return {
      status,
    };
  },
});
