import {
  ionAlert,
  openToast
} from "@/composables/ionicControllers";

export const openAlert = (text: string, handler: () => void, handler2?: () => void): any => {
  return ionAlert({
    cssClass: 'custom-alert',
    header: 'Confirm!',
    message: `Are you sure you want to ${text}?`,
    cancelButton: {
      text: 'No',
      role: 'cancel',
      cssClass: 'cancel-button',
      handler: handler2? handler2 : () => null
    },
    confirmButton: {
      text: 'Yes',
      role: 'confirm',
      cssClass: 'confirm-button',
      handler
    }
  })
}

export const onShare = (data: { [key: string]: any}): void => {
  console.log({data})
  if (navigator.share) {
  navigator
    .share({
      ...data
    })
    .then(() => console.log("Share was successful."))
    .catch(() => console.log("Sharing failed"));
} else {
  const cb = navigator.clipboard;
  cb.writeText(data.text || data.url).then(() => openToast("Copied!"));
  // alert("Your browser doesn't support the Share Intent");
}
}